import { FC, Fragment } from 'react';
import { logEvent } from '@firebase/analytics';
import ReactHtmlParser from 'react-html-parser';
import { Checkbox } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ITables, ITable } from './Tables.props';
import formatNumber from '../../../../../utils/numbers/formatNumber';
import analytics from '../../../../../utils/firebase';

const Tables: FC<ITables> = ({
  tables,
  disabledItems,
  disabledItemsDiagnostics,
  currencySelected,
  selectedTests,
  selectedItemsDiagnostics,
  setDisabledItems,
  setDisabledItemsDiagnostics,
  setSelectedTests,
  setSelectedItemsDiagnostics,
  isCOP,
  oneDollarInCop,
  values,
}) => {
  const valueRow = (
    priceRow: Record<string, number>,
    row: { id: string },
  ): string | number => (isCOP
    ? formatNumber({
      currencySelected,
      oneDollarInCop,
      number: priceRow[row.id],
    })
    : priceRow[row.id]);

  const priceRow = (rowPrice: number): string | number => (isCOP
    ? formatNumber({ currencySelected, oneDollarInCop, number: rowPrice })
    : rowPrice);

  return (
    <>
      {tables.map((table: ITable) => (
        <Fragment key={table.nameTable}>
          <table>
            <thead>
              <tr className="headerTable">
                <th className="headerTitle">{table.nameTable}</th>
                <th>Cotizar</th>
                <th>Valor Final ({currencySelected})</th>
              </tr>
            </thead>
            <tbody>
              {table.rows.map((row) => (
                <tr
                  key={row.id}
                  className={
                    disabledItems[row.id] || disabledItemsDiagnostics[row.id]
                      ? 'disabledTr'
                      : ''
                  }>
                  <td className="nameRow">
                    {ReactHtmlParser(row.name)}
                    <div className="testPrice">${priceRow(row.price)}</div>
                  </td>
                  <td className="valeRow" style={{ width: '50px' }}>
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<FiberManualRecordIcon />}
                      checked={
                        selectedTests[row.id]
                        || false
                      }
                      disabled={
                        disabledItems[row.id]
                        ?? false
                    }
                      name={row.id}
                      onChange={(e) => {
                        const newSelectedTests = {} as Record<string, boolean>;
                        const newSelectedTestsDiagnostics = {} as Record<
                          string,
                          boolean
                        >;
                        const newDisabledTests = {} as Record<string, boolean>;
                        const newDisabledTestsDiagnostics = {} as Record<
                          string,
                          boolean
                        >;
                        newSelectedTests[e.target.name] = e.target.checked;
                        newSelectedTestsDiagnostics[e.target.name] = e.target.checked;
                        const disabledRows = (
                          // eslint-disable-next-line no-unused-vars
                          disabledRowsColors: (newDisabled: Record<string, boolean>) => void,
                          disabledRowsDiagnostics: (
                            // eslint-disable-next-line no-unused-vars
                            newDisabledDiagnostic: Record<string, boolean>
                          ) => void,
                        ) => {
                          if (row.disableItems) {
                            row.disableItems.forEach((disableItemId) => {
                              newDisabledTests[disableItemId] = e.target.checked;
                            });
                            disabledRowsColors(newDisabledTests);
                          }
                          if (row.disableItemsDiagnostics) {
                            row.disableItemsDiagnostics.forEach(
                              (disableItemId) => {
                                newDisabledTestsDiagnostics[disableItemId] = e.target.checked;
                              },
                            );
                            disabledRowsDiagnostics(
                              newDisabledTestsDiagnostics,
                            );
                          }
                        };
                        disabledRows(
                          setDisabledItems,
                          setDisabledItemsDiagnostics,
                        );
                        setSelectedTests({
                          ...selectedTests,
                          ...newSelectedTests,
                          ...newDisabledTests,
                        });
                        setSelectedItemsDiagnostics({
                          ...selectedItemsDiagnostics,
                          ...newSelectedTestsDiagnostics,
                          ...newDisabledTestsDiagnostics,
                        });
                        logEvent(analytics, 'testSelect');
                      }}
                    />
                  </td>
                  <td className="valeRow" style={{ width: '100px' }}>
                    ${valueRow(values, row)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="warning">{table.textAlert}</p>
          <br />
        </Fragment>
      ))}
    </>
  );
};

export default Tables;
