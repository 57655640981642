import { FC } from 'react';
import socialNetwors from './footer.const';
import './style.css';

const Footer: FC = () => (
  <footer>
    <div className="socialIcons">
      {socialNetwors.map(({ link, icon }) => (
        <a href={link} key={link} target="_blank" rel="noopener noreferrer">
          {icon}
        </a>
      ))}
    </div>
    <p>Todos los derechos reservados &copy; Tabio - Cundinamarca</p>
  </footer>
);
export default Footer;
