import { FC, useMemo } from 'react';
import { Button } from '@material-ui/core';
import { isSupported, logEvent } from '@firebase/analytics';
import { IRenderTotals } from './renderTotals.props';
import formatNumber from '../../../../../utils/numbers/formatNumber';
import analytics from '../../../../../utils/firebase';

const RenderTotals: FC<IRenderTotals> = ({
  currency,
  setCurrency,
  oneDollarInCop,
  isAnimalGenetics,
  currencies,
  isCOP,
  subTotal,
  laboratorySelected,
}) => {
  const getPrices = useMemo(() => {
    const dollarPrice = oneDollarInCop || 1;
    const shipping = laboratorySelected.shipping / dollarPrice;
    const valueCommissions = laboratorySelected.commissions;
    const commissions = subTotal * valueCommissions;
    const total = subTotal + shipping + commissions;
    return { total, commissions, shipping };
  }, [oneDollarInCop, subTotal, laboratorySelected]);

  const { total, commissions, shipping } = getPrices;
  return (
    <div className="totalsContainer">
      <div className="currencyButtons">
        <Button
          onClick={() => {
            setCurrency(currencies.USD);
            isSupported().then(() => {
              logEvent(analytics, 'currencies.USD');
            });
          }}
          className={!isCOP ? 'select' : ''}
          variant="contained">
          Dolares
        </Button>
        <Button
          onClick={() => {
            setCurrency(currencies.COP);
            logEvent(analytics, 'currencies.COP');
          }}
          className={isCOP ? 'select' : ''}
          variant="contained">
          Pesos Colombianos
        </Button>
      </div>
      <div className="totals">
        <p>
          <span>Laboratorio:</span>
          <span className="pricing">{laboratorySelected.name}</span>
        </p>
        <p>
          <span>
            Subtotal <span className="currency">{`(${currency})`}</span>:
          </span>
          <span className="pricing">
            $
            {formatNumber({
              currencySelected: currency,
              oneDollarInCop,
              number: subTotal,
            })}
          </span>
        </p>
        <p>
          <span>
            {`Comisión ${isAnimalGenetics ? '10%' : '20%'}`}
            <span className="currency">{`(${currency})`}</span>*:
          </span>
          <span className="pricing">
            $
            {formatNumber({
              currencySelected: currency,
              oneDollarInCop,
              number: commissions,
            })}
          </span>
        </p>
        <p>
          <span>
            Envío <span className="currency">{`(${currency})`}</span>:
          </span>
          <span className="pricing">
            $
            {formatNumber({
              currencySelected: currency,
              oneDollarInCop,
              number: shipping,
            })}
          </span>
        </p>
        <br />
        <br />
        <p>
          <span>
            Total <span className="currency">{`(${currency})`}</span>:
          </span>
          <span className="pricing total">
            $
            {formatNumber({
              currencySelected: currency,
              oneDollarInCop,
              number: total,
            })}
          </span>
        </p>
        {!isCOP && (
          <p className="warning">
            Todos los pagos en dólares se realizan a través de PayPal
          </p>
        )}
        {isCOP && (
          <p className="warning">
            Valor aproximado calculando el TRM del día de hoy ($
            {oneDollarInCop}
            ), el valor final se da al momento de hacer el pago.
          </p>
        )}
      </div>
    </div>
  );
};

export default RenderTotals;
