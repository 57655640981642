import { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Home from './common/components/Home';

const App: FC = () => (
  <Router>
    <Home />
  </Router>
);

export default App;
