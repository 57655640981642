import YouTubeIcon from '@material-ui/icons/YouTube';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';

const socialNetwors = [
  {
    link: 'https://www.instagram.com/ledogco/?hl=es-la',
    icon: <InstagramIcon />,
  },
  {
    link: 'https://www.youtube.com/channel/UCDcWZL9aLF50XT1ekbQDaGA',
    icon: <YouTubeIcon />,
  },
  {
    link: 'https://wa.me/573197834460',
    icon: <WhatsAppIcon />,
  },
];

export default socialNetwors;
