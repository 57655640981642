/* eslint-disable no-shadow */

import { IRowsItems, ITable } from './components/Tables/Tables.props';

/* eslint-disable no-unused-vars */
export type currencies = 'USD' | 'COP';

export enum laboratory {
  ANIMAL_GENETICS = 'ANIMAL_GENETICS',
  UC_DAVIS = 'UC_DAVIS',
  LABOKLIN = 'LABOKLIN',
}

export interface ILaboratorySelected {
  name: string;
  variousElements: number;
  test: IRowsItems[];
  shipping: number;
  commissions: number;
  table?: ITable[];
}
