export const PRICE_SINGLE_ELEMENT = 40;
export const PRICE_VARIOUS_ELEMENTS = 25;
export const PRICE_FULL_COLOR_PANEL = 130;
export const PRICE_DISEASES_PANEL = 120;
export const PRICE_FULL_PANEL = 195;
export const SHIPPING_COST_COP_ANIMAL_GENETICS = 20000;
export const SHIPPING_COST_COP_UC_DAVIS = 30000;

export const PRICE_SINGLE_ELEMENT_UC_DAVIS = 45;
export const PRICE_VARIOUS_ELEMENTS_UC_DAVIS = 20;
export const PRICE_VARIOUS_ELEMENTS_UC_DAVIS_2 = 15;
export const PRICE_SINGLE_DIAGNOSTIC_ELEMENT_UC_DAVIS = 50;
export const PRICE_VARIOUS_DIAGNOSTICS_ELEMENTS_UC_DAVIS = 30;
export const PRICE_BASIC_COLOR_PANEL_UC_DAVIS = 90;
export const PRICE_BULLDOG_PANEL_UC_DAVIS = 75;
export const PRICE_FRENCH_BULLDOG_HEALTH_UC_DAVIS = 130;

export const PRICE_SINGLE_ELEMENT_LABOKLIN = 75;
export const PRICE_VARIOUS_ELEMENTS_LABOKLIN = 35;

export const USD_TO_COP = 3642.64;
export const GAP_DOLLAR_PRICE = 100;
export const COMMISSION_ANIMAL_GENETICS = 0.1;
export const COMMISSION_UC_DAVIS = 0.2;
