import { PRICE_SINGLE_ELEMENT_LABOKLIN } from '../../prices';

export const testLaboklin = [
  {
    name: 'C Locus <span>(Albino, caL, OCA2)</span>',
    id: 'cLocus',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT_LABOKLIN,
  },
  {
    name: 'I - Locus <span>(phaeomelanin intensity)</span> ',
    id: 'b',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT_LABOKLIN,
  },
];

export const tablesLaboklin = [
  {
    nameTable: 'Color',
    rows: testLaboklin,
    textAlert: '*Los precios están sujetos a cambio por Laboklin*',
  },
];
