import {
  PRICE_SINGLE_ELEMENT,
  PRICE_FULL_COLOR_PANEL,
  PRICE_DISEASES_PANEL,
  PRICE_FULL_PANEL,
} from '../../prices';
import { IRowsItems } from '../Tables/Tables.props';

const rowsCoatColorAnimalGenetics = [
  {
    name: 'At Locus <span>(At Aw Ay a)</span>',
    id: 'at',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT,
  },
  {
    name: 'B Locus <span>(Chocolate testeable)</span>',
    id: 'b',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT,
  },
  {
    name: 'Cocoa <span>(Antiguo Chocolate no testeable)</span>',
    id: 'cocoa',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT,
  },
  {
    name: 'D Locus <span>(Blue)</span>',
    id: 'd',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT,
  },
  {
    name: 'E Locus <span>(Crema y mascara)</span>',
    id: 'e',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT,
  },
  {
    name: 'K Locus <span>(Brindle y negro dominante)</span>',
    id: 'k',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT,
  },
  {
    name: 'S Locus <span>(Pied)</span>',
    id: 's',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT,
  },
  {
    name: '* Panel de color completo',
    id: 'fullPanelColor',
    disableItems: ['at', 'd', 'cocoa', 'b', 'k', 's', 'e'],
    price: PRICE_FULL_COLOR_PANEL,
  },
] as IRowsItems[];

const merleAndFluffyAnimalGenetics = [
  {
    name: '<span>Merle</span>',
    id: 'merle',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT,
  },
  {
    name: 'L Locus <span>(fluffy)</span>',
    id: 'l',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT,
  },
] as IRowsItems[];

const rowsGeneticDisorderAnimalGenetics = [
  {
    name: 'CMR1, DM, HUU, JHC',
    id: 'fullPanelDiseases',
    price: PRICE_DISEASES_PANEL,
  },
  {
    name:
      'Color <span>(sin merle)</span>, enfermedades y fluffy <span>(Complete Panel)</span>',
    id: 'fullPanel',
    disableItems: [
      'at',
      'd',
      'cocoa',
      'b',
      'k',
      's',
      'e',
      'fullPanelColor',
      'fullPanelDiseases',
      'l',
    ],
    price: PRICE_FULL_PANEL,
  },
] as IRowsItems[];

export const testsAnimalGenetics = [
  ...rowsCoatColorAnimalGenetics,
  ...merleAndFluffyAnimalGenetics,
  ...rowsGeneticDisorderAnimalGenetics,
];

export const tablesAnimalGenetics = [
  {
    nameTable: 'Color',
    rows: rowsCoatColorAnimalGenetics,
  },
  {
    nameTable: 'Merle y Fluffy',
    rows: merleAndFluffyAnimalGenetics,
  },
  {
    nameTable: 'Combo - Test de desorden genético canino',
    rows: rowsGeneticDisorderAnimalGenetics,
    textAlert: '*Los precios están sujetos a cambio por Animals Genetics*',
  },
];
