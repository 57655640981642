import { useState, useEffect, FC } from 'react';
import { Button } from '@material-ui/core';
import Axios from 'axios';
import { logEvent } from '@firebase/analytics';
import { useLocation } from 'react-router-dom';
import {
  testsAnimalGenetics,
  tablesAnimalGenetics,
} from './components/Labs/AnimalGenetics.const';
import { testsUcDavis, tablesUcDavis } from './components/Labs/UcDavis.const';
import * as prices from './prices';
import RenderTotals from './components/RenderTotals';
import Tables from './components/Tables';
import './style.css';
import { IRowsItems } from './components/Tables/Tables.props';
import analytics from '../../../utils/firebase';
import config from '../../../config.json';
import { testLaboklin, tablesLaboklin } from './components/Labs/Laboklin.const';
import { ILaboratorySelected } from './rates.props';
import indentyRoute from '../../../utils/route/indentyRoute';

const currencies = { COP: 'COP', USD: 'USD' };
const laboratory = {
  ANIMAL_GENETICS: 'ANIMAL_GENETICS',
  UC_DAVIS: 'UC_DAVIS',
  LABOKLIN: 'LABOKLIN',
};

const Rates: FC = () => {
  const location = useLocation();
  const [subTotal, setSubtotal] = useState<number>(0);
  const [values, setValues] = useState<IRowsItems | {}>({});
  const [selectedTests, setSelectedTests] = useState<Record<string, boolean>>(
    {},
  );
  const [disabledItems, setDisabledItems] = useState<Record<string, boolean>>(
    {},
  );
  const [selectedItemsDiagnostics, setSelectedItemsDiagnostics] = useState<
    Record<string, boolean>
  >({});
  const [disabledItemsDiagnostics, setDisabledItemsDiagnostics] = useState<
    Record<string, boolean>
  >({});
  const [selectedDNALaboratory, setSelectedDNALaboratory] = useState<string>(
    indentyRoute(location.pathname),
  );
  const [currencySelected, setCurrencySelected] = useState(currencies.COP);
  const [oneDollarInCop, setOneDollarInCop] = useState(0);

  const isAnimalGenetics = selectedDNALaboratory === laboratory.ANIMAL_GENETICS;
  let laboratorySelected = {} as ILaboratorySelected;
  switch (selectedDNALaboratory) {
    case laboratory.ANIMAL_GENETICS:
      laboratorySelected = {
        name: 'Animal Genetics',
        variousElements: prices.PRICE_VARIOUS_ELEMENTS,
        test: testsAnimalGenetics,
        shipping: prices.SHIPPING_COST_COP_ANIMAL_GENETICS,
        commissions: prices.COMMISSION_ANIMAL_GENETICS,
        table: tablesAnimalGenetics,
      };
      break;
    case laboratory.UC_DAVIS:
      laboratorySelected = {
        name: 'UC Davis',
        variousElements: prices.PRICE_VARIOUS_ELEMENTS_UC_DAVIS,
        test: testsUcDavis,
        shipping: prices.SHIPPING_COST_COP_UC_DAVIS,
        commissions: prices.COMMISSION_UC_DAVIS,
        table: tablesUcDavis,
      };
      break;
    case laboratory.LABOKLIN:
      laboratorySelected = {
        name: 'Laboklin',
        variousElements: prices.PRICE_VARIOUS_ELEMENTS_LABOKLIN,
        test: testLaboklin,
        shipping: prices.SHIPPING_COST_COP_UC_DAVIS,
        commissions: prices.COMMISSION_UC_DAVIS,
        table: tablesLaboklin,
      };
      break;
    default:
      break;
  }

  const pricesVariousElements = laboratorySelected?.variousElements as number;
  const isCOP = currencySelected === currencies.COP;

  useEffect(() => {
    Axios(config.URL_API_CURRENCY)
      .then((answer: { data: { rates: { name: string; rate: number }[] } }) => {
        const currencyCopToUsd = answer.data.rates.filter(
          (currencie: { name: string }) => currencie.name === 'Colombia',
        );
        setOneDollarInCop(currencyCopToUsd[0].rate + prices.GAP_DOLLAR_PRICE);
      })
      .catch(() => {
        setOneDollarInCop(prices.USD_TO_COP + prices.GAP_DOLLAR_PRICE);
      });
  }, []);

  useEffect(() => {
    const newValues = {} as Record<string, number>;
    let valuesProcessed = 0;
    let valuesProcessedDiagnostic = 0;

    const getNewValue = (newValue: number): number => {
      if (valuesProcessed > 2 && !isAnimalGenetics) {
        return prices.PRICE_VARIOUS_ELEMENTS_UC_DAVIS_2;
      }
      if (valuesProcessed > 0) {
        return pricesVariousElements;
      }
      return newValue;
    };

    const disabled = (test: IRowsItems) => {
      if (disabledItems[test.id]) {
        valuesProcessed += 1;
      }
      if (disabledItemsDiagnostics[test.id]) {
        valuesProcessedDiagnostic += 1;
      }
    };

    laboratorySelected.test.forEach((test: IRowsItems) => {
      if ([!disabledItems[test.id], selectedTests[test.id]].every(Boolean)) {
        let newValue = test.price as number;

        if ([test.applyMultipleDiscount, !test.testDiagnostic].every(Boolean)) {
          newValue = getNewValue(newValue);
        } else if (
          [test.applyMultipleDiscount, test.testDiagnostic].every(Boolean)
        ) {
          if (valuesProcessedDiagnostic > 0) {
            newValue = prices.PRICE_VARIOUS_DIAGNOSTICS_ELEMENTS_UC_DAVIS;
          }
        }
        if (test.testDiagnostic) {
          valuesProcessedDiagnostic += 1;
        }
        valuesProcessed += 1;
        newValues[test.id] = newValue;
      } else {
        disabled(test);
        newValues[test.id] = 0;
      }
    });
    setValues(newValues);
    setSubtotal(Object.values(newValues).reduce((acc, i) => i + acc, 0));
  }, [
    selectedTests,
    disabledItems,
    selectedDNALaboratory,
    isAnimalGenetics,
    pricesVariousElements,
    disabledItemsDiagnostics,
    laboratorySelected.test,
  ]);

  const renderButtonsSelectLaboratory = () => {
    const laboratories = [
      { id: laboratory.ANIMAL_GENETICS, name: 'Animal Genetics' },
      { id: laboratory.UC_DAVIS, name: 'UC Davis' },
      { id: laboratory.LABOKLIN, name: 'LABOKLIN' },
    ];

    return (
      <>
        <p className="subTitle">
        (Selecciona cada gen para cotizar){' '}
        <span role="img" aria-label="circle">
          〇
        </span>
        <img className="hand" src="/resources/svg/hand.svg" alt="hand" />
        </p>
        <div className="currencyButtons">
          {laboratories.map(({ id, name }) => (
            <Button
              key={id}
              onClick={() => {
                setSelectedDNALaboratory(id);
                setSelectedTests({});
                setValues({});
                setDisabledItems({});
                setSelectedItemsDiagnostics({});
                setDisabledItemsDiagnostics({});
                setSubtotal(0);
                logEvent(analytics, 'laboratory');
              }}
              className={selectedDNALaboratory === id ? 'select' : ''}
              variant="contained">
              {name}
            </Button>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="sectionRates" id="sectionRates">
      <h2>Listado de tarifas</h2>
      <p className="subTitle">Por favor seleccione su laboratorio</p>
      {renderButtonsSelectLaboratory()}
      <Tables
        tables={laboratorySelected.table ?? []}
        disabledItems={disabledItems}
        disabledItemsDiagnostics={disabledItemsDiagnostics}
        currencySelected={currencySelected}
        selectedTests={selectedTests}
        selectedItemsDiagnostics={selectedItemsDiagnostics}
        setDisabledItems={setDisabledItems}
        setDisabledItemsDiagnostics={setDisabledItemsDiagnostics}
        setSelectedTests={setSelectedTests}
        setSelectedItemsDiagnostics={setSelectedItemsDiagnostics}
        isCOP={isCOP}
        oneDollarInCop={oneDollarInCop}
        values={values}
      />
      <p className="messageWarning">
        ** En Le Dog gestionamos todo el proceso de testeo con los distintos
        laboratorios para agilizar resultados y trámites, obteniendo resultados
        desde 8 días enviada la muestra. No obstante, por parte de los
        laboratorios y empresas de transporte pueden presentarse retrasos,
        fallos u otros inconvenientes de los cuales no somos responsables, pero
        realizaremos las comunicaciones y gestiones necesarias para solventar y
        lograr tener con éxito los resultados, es de comprender que en estos
        casos aislados el tiempo para obtener los resultados será mayor. **
      </p>
      <RenderTotals
        currencies={currencies}
        currency={currencySelected}
        setCurrency={setCurrencySelected}
        isAnimalGenetics={isAnimalGenetics}
        oneDollarInCop={oneDollarInCop}
        isCOP={isCOP}
        subTotal={subTotal}
        laboratorySelected={laboratorySelected}
      />
      <div className="message">
        <p>
          En Le Dog gestionamos todo el proceso de testeo con los distintos
          laboratorios para agilizar resultados y trámites, obteniendo
          resultados desde 8 días enviada la muestra. No obstante, por parte de
          los laboratorios y empresas de transporte pueden presentarse retrasos,
          fallos u otros inconvenientes de los cuales no somos responsables,
          pero realizaremos las comunicaciones y gestiones necesarias para
          solventar y lograr tener con éxito los resultados, es de comprender
          que en estos casos aislados el tiempo para obtener los resultados será
          mayor.
        </p>
        <p>
          Para adquirir este servicio, escríbenos a través de nuestros canales
          de contacto vía <br /> <strong>WhatsApp</strong> o{' '}
          <strong>Chat</strong>
        </p>
      </div>
    </div>
  );
};

export default Rates;
