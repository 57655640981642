import { laboratory } from '../../common/components/Rates/rates.props';

const indentyRoute = (url: string): string => {
  const labs = url.replace('/', '');
  switch (labs) {
    case laboratory.ANIMAL_GENETICS:
      return laboratory.ANIMAL_GENETICS;
    case laboratory.UC_DAVIS:
      return laboratory.UC_DAVIS;
    case laboratory.LABOKLIN:
      return laboratory.LABOKLIN;

    default:
      return laboratory.ANIMAL_GENETICS;
  }
};

export default indentyRoute;
