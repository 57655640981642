import { FC } from 'react';
import Banner from './Banner';
import Footer from './Footer';
import Header from './Header';
import Rates from './Rates';
import Steps from './Steps';

const Home: FC = () => (
  <>
    <Header />
    <Banner />
    <Steps />
    <Rates />
    <Footer />
  </>
);

export default Home;
