export const formatNumber = ({
  currencySelected,
  oneDollarInCop,
  number,
}: {
  currencySelected: string;
  oneDollarInCop: number;
  number: number;
}): string => {
  const numberRound = number;
  const typeCurrency = currencySelected === 'COP'
    ? Math.trunc(oneDollarInCop * numberRound)
    : numberRound;
  const finalCOP = new Intl.NumberFormat('es-ES').format(typeCurrency);
  const finalUSD = Number(typeCurrency)
    .toFixed(2)
    .replace(/\.?0+$/, '');
  return currencySelected === 'COP' ? finalCOP : finalUSD;
};

export default formatNumber;
