import {
  PRICE_BASIC_COLOR_PANEL_UC_DAVIS,
  PRICE_BULLDOG_PANEL_UC_DAVIS,
  PRICE_FRENCH_BULLDOG_HEALTH_UC_DAVIS,
  PRICE_SINGLE_DIAGNOSTIC_ELEMENT_UC_DAVIS,
  PRICE_SINGLE_ELEMENT,
  PRICE_SINGLE_ELEMENT_UC_DAVIS,
} from '../../prices';
import { IRowsItems } from '../Tables/Tables.props';

const rowsCoatColorsUcDavis = [
  {
    name: 'At Locus <span>(At Aw Ay a)</span>',
    id: 'at',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT_UC_DAVIS,
  },
  {
    name: 'B Locus <span>(Chocolate testeable)</span>',
    id: 'b',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT_UC_DAVIS,
  },
  {
    name: 'Cocoa <span>(Antiguo Chocolate no testeable)</span>',
    id: 'cocoa',
    applyMultipleDiscount: false,
    price: PRICE_SINGLE_ELEMENT,
  },
  {
    name: 'D Locus <span>(Blue)</span>',
    id: 'd',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT_UC_DAVIS,
  },
  {
    name: 'E Locus <span>(Crema y mascara)</span>',
    id: 'e',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT_UC_DAVIS,
  },
  {
    name: 'K Locus <span>(Brindle y negro dominante)</span>',
    id: 'k',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT_UC_DAVIS,
  },
  {
    name: 'S Locus <span>(Pied)</span>',
    id: 's',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT_UC_DAVIS,
  },
  {
    name: 'I Locus <span>(Intensity Dilution)</span>',
    id: 'i',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT_UC_DAVIS,
  },
] as IRowsItems[];

const dogsCoatColorPanelUcDavis = [
  {
    name: '* Panel básico <span>(Agouti + E + B + D + K + S )</span>',
    id: 'basicPanel',
    disableItems: [
      'at',
      'd',
      'b',
      'k',
      's',
      'e',
      'bulldogPanel',
      'frenchBulldogPanel',
    ],
    price: PRICE_BASIC_COLOR_PANEL_UC_DAVIS,
  },
  {
    name: '* Bulldog panel <span>(Agouti + B + D + K + S)</span>',
    id: 'bulldogPanel',
    disableItems: [
      'at',
      'd',
      'b',
      'k',
      's',
      'basicPanel',
      'frenchBulldogPanel',
    ],
    price: PRICE_BULLDOG_PANEL_UC_DAVIS,
  },
  {
    name: '* Bulldog fracés panel <span>(Agouti + E + D + K + S)</span>',
    id: 'frenchBulldogPanel',
    disableItems: ['at', 'd', 'e', 'k', 's', 'basicPanel', 'bulldogPanel'],
    price: PRICE_BULLDOG_PANEL_UC_DAVIS,
  },
] as IRowsItems[];

const merleAndFluffyUcDavis = [
  {
    name: '<span>Merle</span>',
    id: 'merle',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT_UC_DAVIS,
  },
  {
    name: 'L Locus <span>(fluffy)</span>',
    id: 'l',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_ELEMENT_UC_DAVIS,
  },
] as IRowsItems[];

const rowsDiagnosticUcDavis = [
  {
    name:
      "<a href='https://vgl.ucdavis.edu/test/cmr1' target='_blank' rel='noopener noreferrer' >Canine Multifocal Retinopathy 1</a> <span>(CMR1)</span>",
    id: 'CMR1',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_DIAGNOSTIC_ELEMENT_UC_DAVIS,
    testDiagnostic: true,
  },
  {
    name:
      "<a href='https://vgl.ucdavis.edu/test/exercise-induced-collapse' target='_blank' rel='noopener noreferrer' >Exercise Induced Collapse </a> <span>(EIC)</span>",

    id: 'EIC',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_DIAGNOSTIC_ELEMENT_UC_DAVIS,
    testDiagnostic: true,
  },
  {
    name:
      "<a href='https://vgl.ucdavis.edu/test/primary-lens-luxation' target='_blank' rel='noopener noreferrer' >Primary Lens Luxation </a> <span>(PLL)</span>",

    id: 'PLL',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_DIAGNOSTIC_ELEMENT_UC_DAVIS,
    testDiagnostic: true,
  },
  {
    name:
      "<a href='https://vgl.ucdavis.edu/test/cmr1' target='_blank' rel='noopener noreferrer' >Chondrodystrophy  and Chondrodysplasia</a>  <span>(CDDY and IVDD Risk + CDPA)</span>",

    id: 'CDDY',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_DIAGNOSTIC_ELEMENT_UC_DAVIS,
    testDiagnostic: true,
  },
  {
    name:
      "<a href='https://vgl.ucdavis.edu/test/hyperuricosuria' target='_blank' rel='noopener noreferrer' >Hyperuricosuria </a> <span>(HUU)</span>",

    id: 'HUU',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_DIAGNOSTIC_ELEMENT_UC_DAVIS,
    testDiagnostic: true,
  },
  {
    name:
      "<a href='https://vgl.ucdavis.edu/test/degenerative-myelopathy' target='_blank' rel='noopener noreferrer' >Degenerative Myelopathy</a> <span>(DM)</span>",

    id: 'DM',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_DIAGNOSTIC_ELEMENT_UC_DAVIS,
    testDiagnostic: true,
  },
  {
    name:
      "<a href='https://vgl.ucdavis.edu/test/juvenile-hereditary-cataract' target='_blank' rel='noopener noreferrer' >Juvenile Hereditary Cataract</a> <span>(JHC)</span>",

    id: 'JHC',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_DIAGNOSTIC_ELEMENT_UC_DAVIS,
    testDiagnostic: true,
  },
  {
    name:
      "<a href='https://vgl.ucdavis.edu/test/vwd-type-1' target='_blank' rel='noopener noreferrer' >Von Willebrand's Disease</a> <span>(vWD Tipo 1)</span>",
    id: 'vWD',
    applyMultipleDiscount: true,
    price: PRICE_SINGLE_DIAGNOSTIC_ELEMENT_UC_DAVIS,
    testDiagnostic: true,
  },
] as IRowsItems[];

const rowsGeneticDisorderUcDavis = [
  {
    name:
      'Bulldog francés panel de salud 1 <span>(CCDY + CMR1 + CDPA + DM + JHC + HUU)',
    id: 'frenchBulldogHealthPanel1',
    disableItems: [
      'CDDY',
      'CMR1',
      'DM',
      'JHC',
      'HUU',
      'frenchBulldogHealthPanel2',
    ],
    price: PRICE_FRENCH_BULLDOG_HEALTH_UC_DAVIS,
    testDiagnostic: true,
  },
  {
    name: 'Bulldog francés panel de salud 2 <span>(CMR1 + DM + JHC + HUU)',
    id: 'frenchBulldogHealthPanel2',
    disableItems: [
      'CMR1',
      'JHC',
      'HUU',
      'DM',
      'frenchBulldogHealthPanel1',
    ],
    price: PRICE_FRENCH_BULLDOG_HEALTH_UC_DAVIS,
    testDiagnostic: true,
  },
] as IRowsItems[];

export const testsUcDavis = [
  ...rowsCoatColorsUcDavis,
  ...dogsCoatColorPanelUcDavis,
  ...merleAndFluffyUcDavis,
  ...rowsDiagnosticUcDavis,
  ...rowsGeneticDisorderUcDavis,
];

export const tablesUcDavis = [
  {
    nameTable: 'Color',
    rows: rowsCoatColorsUcDavis,
  },
  {
    nameTable: 'Panel de color',
    rows: dogsCoatColorPanelUcDavis,
  },
  {
    nameTable: 'Merle y Fluffy',
    rows: merleAndFluffyUcDavis,
  },
  {
    nameTable: 'Test de desorden genético canino',
    rows: rowsDiagnosticUcDavis,
  },
  {
    nameTable: 'Combo - Test de desorden genético canino',
    rows: rowsGeneticDisorderUcDavis,
    textAlert: '*Los precios están sujetos a cambio por UC Davis*',
  },
];
