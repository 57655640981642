import { FC } from 'react';
import steps from './steps.const';
import './style.css';

const Steps: FC = () => (
  <div className="sectionSteps">
    <h2>¡Es muy fácil!</h2>
    <div className="containerSteps">
      {steps.map(({
        title, svg, description, step,
      }) => (
        <div key={step} className="step">
          <div className="containerImage">
            <p>{step}</p>
            <img src={svg} alt={title} />
          </div>
          <p className="descriptionStep">{description}</p>
        </div>
      ))}
    </div>
  </div>
);

export default Steps;
