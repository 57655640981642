import { FC } from 'react';
import './style.css';

const Banner: FC = () => (
  <div className="containerBanner">
    <img src="/resources/img/banner.jpg" alt="dogs" className="banner" />
    <h1>¿Quieres saber el ADN de tu Frenchie?</h1>
  </div>
);

export default Banner;
