import { BaseSyntheticEvent, useState, FC } from 'react';
import { Button, Menu } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import './style.css';

const Header: FC = () => {
  const [openButton, setOpenButton] = useState(null);

  const handleClick = (event: BaseSyntheticEvent) => {
    setOpenButton(event?.currentTarget);
  };

  const handleClose = () => {
    setOpenButton(null);
  };

  const links = [{ url: '#sectionRates', name: 'Cotizar' }];

  const open = Boolean(openButton);
  return (
    <header>
      <img src="/resources/img/logoLedog.png" alt="logo" className="logo" />
      <div className="links">
        {links.map(({ url, name }) => (
          <a href={url} key={url}>
            {name}
          </a>
        ))}
      </div>
      <div className="menuMovil">
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MenuIcon />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={openButton}
          keepMounted
          open={open}
          className="menu"
          onClose={handleClose}
        >
          {links.map(({ url, name }) => (
            <a onClick={handleClose} href={url} key={url}>
              {name}
            </a>
          ))}
        </Menu>
      </div>
    </header>
  );
};

export default Header;
