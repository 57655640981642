import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBkQWK6IQnws57WZVzNbI5en6IBUZ7YSVg',
  authDomain: 'cotizador-adn.firebaseapp.com',
  databaseURL: 'https://cotizador-adn.firebaseio.com',
  projectId: 'cotizador-adn',
  storageBucket: 'cotizador-adn.appspot.com',
  messagingSenderId: '24655400195',
  appId: '1:24655400195:web:1b0d5300974f61c0c42d36',
  measurementId: 'G-93TBVJW4RM',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics;
