const steps = [
  {
    step: '1',
    svg: '/resources/svg/microscope.svg',
    title: 'test',
    description: 'Elige el laboratorio donde quieres realizar el test',
  },
  {
    step: '2',
    svg: '/resources/svg/contact.svg',
    title: 'test',
    description: 'Elige los genes que deseas testear',
  },
  {
    step: '3',
    svg: '/resources/svg/dna.svg',
    title: 'dna',
    description: 'Toma la muestra de tu bulldog francés',
  },
  {
    step: '4',
    svg: '/resources/svg/tracking.svg',
    title: 'tracking',
    description: 'Envíanos la muestra',
  },
  {
    step: '5',
    svg: '/resources/svg/pay.svg',
    title: 'pay',
    description: 'Contáctanos y haz el pago',
  },
  {
    step: '6',
    svg: '/resources/svg/email.svg',
    title: 'email',
    description: 'Espera los resultados',
  },
];

export default steps;
